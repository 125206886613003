import { Input as AntdInput, Menu } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group'; // ES6
import styled, { css } from 'styled-components';

import { CrossSvg, LogoSvg, MenuSvg } from '../../assets/icons';
import CuripodDevSvg from '../../assets/icons/CuripodDevLogo';
import Logo from '../../assets/icons/Logo';
import PadlockSvg from '../../assets/icons/PadlockSvg';
import PinSvg from '../../assets/icons/PinSvg';
import theme from '../../assets/theme';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import useRouterMatch from '../../hooks/useRouterMatch';
import useSignIn from '../../hooks/useSignIn';
import { useMarketingMetrics } from '../../providers/PlausibleAnalyticsProvider';
import Metrics from '../../services/metrics';
import { isProduction } from '../../utils/client';
import { notUndefined } from '../../utils/general';
import { configureStore } from '../../utils/store';
import { zIndexes } from '../../utils/zIndexes';
import { Input } from '../_atoms/_Input';
import { Button } from '../_atoms/Button';
import { IconWrapper } from '../_atoms/IconWrapper';
import { TopBarDropdowns } from '../layouts/NewAppLayout';
export const LandingPageHeader = ({
  children,
  hideBlobs,
}: {
  children?: React.ReactNode;
  hideBlobs?: boolean;
}) => {
  const isDesktop = useBreakPoint(1100);

  if (isDesktop)
    return (
      <CssOverwriteWrapper>
        <DesktopNav hideBlobs={hideBlobs}>{children}</DesktopNav>
      </CssOverwriteWrapper>
    );
  return (
    <CssOverwriteWrapper>
      <MobileNav hideBlobs={hideBlobs}>{children}</MobileNav>
    </CssOverwriteWrapper>
  );
};

const PinInput = () => {
  const [pin, setPin] = useState('');
  const handleEnterPin = (pinToUse: string) => {
    if (pinToUse) {
      window.open(`https://${configureStore().config.game.url}/join/${pinToUse}`);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Prevent typing non-numeric characters
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPin(e.target.value);
  };

  return (
    <AntdInput.Group compact>
      <Input
        type="text"
        onPressEnter={e => handleEnterPin((e.target as HTMLInputElement).value)}
        onKeyPress={handleKeyPress}
        onChange={handleChange}
        placeholder="Enter pin to join lesson"
        inputStyle={{
          border: `1px solid ${theme.colors.grey}`,
          padding: '5px 10px',
          borderRadius: '5px 0px 0px 5px',
          height: 35,
          marginLeft: 7,
        }}
      />
      <JoinButton onClick={() => handleEnterPin(pin)} type="ghost">
        Join
      </JoinButton>
    </AntdInput.Group>
  );
};

const JoinButton = styled(Button)`
  background-color: ${theme.colors.blue};
  color: ${theme.colors.white};
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  padding: 5px 10px;
  border: 1px solid ${theme.colors.grey};
  height: 35px;

  &:hover,
  &:focus,
  &:active {
    background-color: ${theme.colors.blueDark};
    color: ${theme.colors.white};
  }
`;

const DesktopNav = ({
  children,
  hideBlobs,
}: {
  children?: React.ReactNode;
  hideBlobs?: boolean;
}) => {
  const session = useSession();
  const isLoggedIn = session && session.status === 'authenticated';
  const isLargerThan1100 = useBreakPoint(1130);
  const router = useRouter();
  const { tenantId } = useRouterMatch();
  const { signIn } = useSignIn();
  const { track } = useMarketingMetrics();
  const { t } = useTranslation('Host');

  return (
    <HeaderContainer $hideBlobs={hideBlobs}>
      <TopBar>
        <Row>
          <Link href={'/'} passHref>
            <a>
              <IconWrapper color={theme.colors.orange} height={'22px'} width={'81px'}>
                {!isProduction() ? <CuripodDevSvg /> : <LogoSvg />}
              </IconWrapper>
            </a>
          </Link>
          <PinInput />
        </Row>
        <LinkContainer>
          <TopBarDropdowns isLandingPage={true} />
          {!isLoggedIn && (
            <>
              <RightSideVerticalDivider />
              <StyledLink
                style={{
                  fontWeight: 700,
                  color: theme.colors.landingPageTopbarColor,
                  cursor: 'pointer',
                  fontSize: isLargerThan1100 ? '22px' : '18px',
                }}
                onClick={() => {
                  if (!isLoggedIn) {
                    track('Navigate.SignIn');
                    signIn({ callbackUrl: '/' });
                  }
                }}
              >
                Log in
              </StyledLink>
            </>
          )}
        </LinkContainer>
        <Row style={{ height: '50px' }}>
          <>
            {session.status !== 'authenticated' && (
              <Link href={'/signup'} passHref>
                <a style={{ color: theme.colors.white, fontWeight: 'bold' }}>
                  <Button
                    type="primary"
                    styles={{
                      backgroundColor: theme.colors.orange,
                      borderRadius: '5px',
                    }}
                    onClick={() => {
                      if (!isLoggedIn) {
                        track('Navigate.SignUp');
                      }
                    }}
                  >
                    Sign Up For Free
                  </Button>
                </a>
              </Link>
            )}

            {session.status === 'authenticated' && (
              <OrangeButton
                type="primary"
                onClick={() => {
                  router.push(`/${tenantId}/home`);
                }}
              >
                Home
              </OrangeButton>
            )}
          </>
        </Row>
      </TopBar>
      <div style={{ width: '100%' }}>{children && children}</div>
    </HeaderContainer>
  );
};

const RightSideVerticalDivider = styled.div`
  height: 30px;
  width: 0px;
  margin: 0 0 0 10px;
  border-left: solid 2px ${theme.colors.black};
`;

const OrangeButton = styled(Button)`
  background-color: ${theme.colors.orange};
  width: 100%;
  height: 42px;
  border-radius: 5px;
  box-shadow: ${theme.effects.magicFloatingShadow};

  span {
    font-size: 20px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${theme.colors.orangeDark};
    box-shadow: none;
  }
`;

const MobileNav = ({
  children,
  hideBlobs,
}: {
  children: ReactNode;
  hideBlobs?: boolean;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const rootSubmenuKeys = ['sub1', 'sub2'];
  const [openKeys, setOpenKeys] = useState(['sub1']);
  const session = useSession();
  const router = useRouter();
  const isLargerThan500 = useBreakPoint(500);
  const isLargerThan660 = useBreakPoint(660);
  const isLoggedIn = session && session.status === 'authenticated';
  const { tenantId } = useRouterMatch();
  const isLoadingAuth = session && session.status === 'loading';
  const { t } = useTranslation('Host');
  const { signIn } = useSignIn();
  const { track } = useMarketingMetrics();
  const onOpenChange = (keys: Array<string>) => {
    const latestOpenKey: string | undefined = keys.find(
      key => openKeys.indexOf(key) === -1,
    );
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const menuItemsNotLoggedIn = [
    isLargerThan660 === false
      ? {
          key: 'signup',
          label: (
            <Link href={'/signup'} passHref>
              <a>
                <StyledLink>
                  <Button
                    styles={{
                      backgroundColor: theme.colors.orange,
                      borderRadius: '20px',
                    }}
                  >
                    Sign Up For Free
                  </Button>
                </StyledLink>
              </a>
            </Link>
          ),
        }
      : undefined,
    isLargerThan660 === false
      ? {
          key: 'login',
          label: (
            <StyledLink
              style={{
                fontWeight: 500,
                fontSize: 18,
                cursor: 'pointer',
                color: theme.colors.white,
              }}
              onClick={() => {
                if (!isLoadingAuth) {
                  signIn({ callbackUrl: '/' });
                }
              }}
            >
              {t('Log in')}
              <ColoredIconWrapper
                height={'20px'}
                width={'20px'}
                $backgroundColor={theme.colors.green}
              >
                <PadlockSvg />
              </ColoredIconWrapper>
            </StyledLink>
          ),
        }
      : undefined,
    {
      key: 'enter-pin',
      label: (
        <Link
          href={Metrics.withMetricsIdentifier(
            `https://${configureStore().config.game.url}`,
          )}
          passHref
        >
          <a>
            <StyledLink
              style={{
                fontWeight: 500,
                fontSize: 18,
                cursor: 'pointer',
                color: 'white',
              }}
            >
              {t('Enter pin')}
              <ColoredIconWrapper
                height={'20px'}
                width={'20px'}
                $backgroundColor={theme.colors.blue}
              >
                <PinSvg />
              </ColoredIconWrapper>
            </StyledLink>
          </a>
        </Link>
      ),
    },
    {
      key: 'pricing',
      label: (
        <>
          <Link href="/pricing" passHref>
            <a onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <StyledLink
                $isActive={router && router.asPath === `/pricing`}
                style={{
                  fontWeight: 500,
                  fontSize: 18,
                  color: theme.colors.white,
                }}
              >
                Pricing
              </StyledLink>
            </a>
          </Link>
        </>
      ),
    },
    {
      key: 'community',
      label: (
        <Link href="/community" passHref>
          <a onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <StyledLink
              $isActive={router && router.asPath === `/community`}
              style={{
                fontWeight: 500,
                fontSize: 18,
                color: theme.colors.white,
              }}
            >
              Teacher community
            </StyledLink>
          </a>
        </Link>
      ),
    },
    {
      key: 'ai-certificate',
      label: (
        <Link href="/ai-certification" passHref>
          <a onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <StyledLink
              $isActive={router && router.asPath === `/ai-certification`}
              style={{
                fontWeight: 500,
                fontSize: 18,
                color: theme.colors.white,
              }}
            >
              Ai certification
            </StyledLink>
          </a>
        </Link>
      ),
    },
    {
      key: 'ambassador-program',
      label: (
        <Link href="/ambassadors" passHref>
          <a onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <StyledLink
              $isActive={router && router.asPath === `/ambassadors`}
              style={{
                fontWeight: 500,
                fontSize: 18,
                color: theme.colors.white,
              }}
            >
              Ambassador program
            </StyledLink>
          </a>
        </Link>
      ),
    },
  ].filter(notUndefined);
  const menuItemsLoggedIn = [
    isLargerThan500 === false
      ? {
          key: 'lessons',
          label: (
            <Button
              onClick={() => {
                router.push(`/${tenantId}/home`);
              }}
              styles={{
                backgroundColor: theme.colors.orange,
                borderRadius: '5px',
              }}
            >
              Home
            </Button>
          ),
        }
      : undefined,

    {
      key: 'enter-pin',
      label: (
        <Link
          href={Metrics.withMetricsIdentifier(
            `https://${configureStore().config.game.url}`,
          )}
          passHref
        >
          <a>
            <StyledLink
              style={{
                fontWeight: 500,
                fontSize: 18,
                cursor: 'pointer',
                color: theme.colors.white,
              }}
            >
              {t('Enter pin')}
              <ColoredIconWrapper
                height={'20px'}
                width={'20px'}
                $backgroundColor={theme.colors.blue}
              >
                <PinSvg />
              </ColoredIconWrapper>
            </StyledLink>
          </a>
        </Link>
      ),
    },
    {
      key: 'pricing',
      label: (
        <>
          <Link href="/pricing" passHref>
            <a onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <StyledLink
                $isActive={router && router.asPath === `/pricing`}
                style={{
                  fontWeight: 500,
                  fontSize: 18,
                  color: theme.colors.white,
                }}
              >
                Pricing
              </StyledLink>
            </a>
          </Link>
        </>
      ),
    },
    {
      key: 'community',
      label: (
        <Link href="/community" passHref>
          <a onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <StyledLink
              $isActive={router && router.asPath === `/community`}
              style={{
                fontWeight: 500,
                fontSize: 18,
                color: theme.colors.white,
              }}
            >
              Teacher community
            </StyledLink>
          </a>
        </Link>
      ),
    },
    {
      key: 'ai-certificate',
      label: (
        <Link href="/ai-certification" passHref>
          <a onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <StyledLink
              $isActive={router && router.asPath === `/ai-certification`}
              style={{
                fontWeight: 500,
                fontSize: 18,
                color: theme.colors.white,
              }}
            >
              Ai certification
            </StyledLink>
          </a>
        </Link>
      ),
    },
    {
      key: 'ambassador-program',
      label: (
        <Link href="/ambassadors" passHref>
          <a onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <StyledLink
              $isActive={router && router.asPath === `/ambassadors`}
              style={{
                fontWeight: 500,
                fontSize: 18,
                color: theme.colors.white,
              }}
            >
              Ambassador program
            </StyledLink>
          </a>
        </Link>
      ),
    },
  ].filter(notUndefined);

  return (
    <>
      <MobileTopBar isOpen={isMenuOpen}>
        <MobileRow>
          <Link href="/" passHref>
            <IconWrapper color={theme.colors.orange} height={'22px'} width={'81px'}>
              <Logo />
            </IconWrapper>
          </Link>
          <ResponsiveTopBarContainer>
            <div
              style={{
                display: 'flex',
              }}
            >
              <StyledLink
                style={{
                  fontWeight: 700,
                  fontSize: 18,
                  cursor: 'pointer',
                  color: theme.colors.black,
                  marginRight: 20,
                }}
                onClick={() => {
                  if (!isLoggedIn) {
                    signIn({ callbackUrl: '/' });
                  }
                }}
              >
                {isLoggedIn ? '' : 'Log in'}
              </StyledLink>
              <Link href={isLoggedIn ? `${tenantId}/home` : '/signup'} passHref>
                <a
                  style={{
                    fontWeight: 500,
                    fontSize: 14,
                    marginRight: 20,
                  }}
                >
                  <Button
                    styles={{
                      backgroundColor: theme.colors.orange,
                      borderRadius: '5px',
                    }}
                  >
                    {isLoggedIn ? 'Home' : 'Sign Up For Free'}
                  </Button>
                </a>
              </Link>
            </div>
          </ResponsiveTopBarContainer>

          <DrawerIconwWrapper
            color={theme.colors.black}
            height={'30px'}
            width={'50px'}
            onClick={e => {
              e.stopPropagation();
              setIsMenuOpen(!isMenuOpen);
            }}
          >
            {!isMenuOpen ? <MenuSvg /> : <CrossSvg />}
          </DrawerIconwWrapper>
        </MobileRow>
      </MobileTopBar>
      <StyleWrapper $isMenuOpen={isMenuOpen}>
        <CSSTransition
          in={isMenuOpen}
          timeout={600}
          key="slide"
          classNames="slide"
          unmountOnExit
        >
          <MenuContainer onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <Expanded>
              <MenuItems>
                <Menu
                  style={{ width: 'max-content', background: 'none' }}
                  defaultOpenKeys={['sub1']}
                  mode="inline"
                  openKeys={openKeys}
                  onOpenChange={onOpenChange}
                  theme="dark"
                  items={isLoggedIn ? menuItemsLoggedIn : menuItemsNotLoggedIn}
                />
              </MenuItems>
            </Expanded>
          </MenuContainer>
        </CSSTransition>
      </StyleWrapper>
      <MobileHeaderContainer $hideBlobs={hideBlobs}>
        {children && children}
      </MobileHeaderContainer>
    </>
  );
};
const ResponsiveTopBarContainer = styled.div`
  padding-left: 20px;
  flex: 1;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;
  @media (max-width: 550px) {
    display: none;
  }
  @media (max-width: 600px) {
    justify-content: flex-end;
  }
`;

const DrawerIconwWrapper = styled(IconWrapper)`
  @media (max-height: 600px) and (orientation: landscape) {
    padding-right: 40px;
  }
`;

const HeaderContainer = styled.div<{ $hideBlobs?: boolean }>`
  position: relative;
  background: ${theme.colors.background};
  width: 100%;
  margin-top: 60px;
  ${({ $hideBlobs }) => !$hideBlobs && `background-image: url('/static/bground.svg')`};
  ${({ $hideBlobs }) => $hideBlobs && `background-color: ${theme.colors.green}`};
  background-size: cover;
`;
const MobileHeaderContainer = styled.div<{ $hideBlobs?: boolean }>`
  position: relative;
  width: 100%;
  background-color: ${theme.colors.background};

  ${({ $hideBlobs }) =>
    !$hideBlobs && `background-image: url('/static/mobilemenubackground.svg')`};
  ${({ $hideBlobs }) => $hideBlobs && `background-color: ${theme.colors.green}`};
  background-size: cover;
`;

const StyleWrapper = styled.div<{ $isMenuOpen: boolean }>`
  top: 60px;
  left: 0;
  z-index: ${zIndexes('LANDING_PAGE_HEADER')};

  padding-top: ${({ $isMenuOpen }) => ($isMenuOpen ? '60px' : '0px')};

  /* ENTER TRANSITION */
  /* Declare transition start properties*/
  .slide-enter {
    transform: translateX(100vw);
    opacity: 0;
  }

  /* Declare transition properties */
  .slide-enter.slide-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 300ms cubic-bezier(0.79, 0.14, 0.15, 0.86);
  }

  /* EXIT TRANSITION */
  .slide-exit {
    transform: translateX(0);
    opacity: 1;
  }

  .slide-exit.slide-exit-active {
    transform: translateX(100vw);
    opacity: 0;
    transition: all 300ms cubic-bezier(0.79, 0.14, 0.15, 0.86);
  }
`;

const MobileRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const MenuContainer = styled.div`
  width: 100%;
  height: 100vh;
  z-index: ${zIndexes('MOBILE_TOP_NAV_LEFT_BAR')};
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  color: ${theme.colors.landingPageTopbarColor};
  padding-top: 10px;
  padding-bottom: 20px;
  right: 0px;
  top: 60px;
`;

const Expanded = styled.div`
  position: relative;
`;
const StyledLink = styled.span<{ $isActive?: boolean }>`
  font-weight: 500;
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 10px 20px;
  white-space: nowrap;

  ${({ $isActive }) =>
    $isActive &&
    css`
      background: ${theme.colors.translucentWhite};
      font-weight: 600 !important;
      border-radius: 10px 0px 0px 10px;
    `}
`;

const ColoredIconWrapper = styled(IconWrapper)<{ $backgroundColor: string }>`
  background: ${({ $backgroundColor }) => $backgroundColor};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 16px;
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  & > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ul {
    color: ${theme.colors.white};
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }

  .ant-menu-sub {
    background: none;
  }

  .ant-menu-submenu-title {
    font-size: 20px;
    font-weight: 400;
    color: ${theme.colors.white};
  }

  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: none;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: none;
    border-right: none;
  }

  .ant-menu-inline > .ant-menu-item {
    height: 60px;
    padding: 5px 0px !important;
    margin: 0px !important;
  }

  @media (max-height: 640px) {
    .ant-menu-inline > .ant-menu-item {
      height: 50px;
    }
  }
`;

const MobileTopBar = styled.div<{ isOpen: boolean }>`
  height: 60px;
  z-index: ${zIndexes('LANDING_PAGE_HEADER')};
  width: 100%;
  flex: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${theme.colors.landingPageTopbarColor};
  background-color: ${theme.colors.landingPageTopbarBackground};
  position: sticky;
  top: 0;
  margin: auto;
  @media (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  flex-wrap: wrap;
  box-shadow: ${({ isOpen }) => (isOpen ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none')};
  position: ${({ isOpen }) => (isOpen ? ' fixed' : '')};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;
const LinkContainer = styled(Row)`
  padding-left: 10px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const TopBar = styled.div`
  height: 60px;
  z-index: ${zIndexes('TOPBAR')};
  width: 100%;
  flex: 0;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${theme.colors.landingPageTopbarColor};
  background-color: ${theme.colors.landingPageTopbarBackground};
  position: fixed;
  top: 0;
  margin: auto;
  @media (max-width: 1128px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  flex-wrap: wrap;
`;

{
  /* due to CMS overriding styles we need to manually override them back to normal*/
}
const CssOverwriteWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  span {
    font-family: Nunito;
  }

  p {
    font-weight: bold;
    line-height: 22px;
    font-size: 14px;
    margin-bottom: 10px;
  }
  a,
  li {
    text-decoration: unset !important;
    line-height: 20px;
  }
  h3 {
    font-size: 1.17em;
    line-height: 1.5715;
    margin-bottom: 0.5em;
    margin-top: 0;
  }
`;
