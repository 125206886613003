import React from 'react';

export const PinSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
    <g
      id="Icon_ionic-ios-keypad"
      data-name="Icon ionic-ios-keypad"
      transform="translate(-4.5 -4.5)"
    >
      <path
        id="Path_6"
        data-name="Path 6"
        d="M27.745,23.991A3.755,3.755,0,1,0,31.5,27.745,3.754,3.754,0,0,0,27.745,23.991Z"
        fill="currentColor"
      />
      <path
        id="Path_7"
        data-name="Path 7"
        d="M18,23.991a3.755,3.755,0,1,0,3.755,3.755A3.754,3.754,0,0,0,18,23.991Z"
        fill="currentColor"
      />
      <path
        id="Path_8"
        data-name="Path 8"
        d="M8.255,23.991a3.755,3.755,0,1,0,3.755,3.755A3.754,3.754,0,0,0,8.255,23.991Z"
        fill="currentColor"
      />
      <path
        id="Path_9"
        data-name="Path 9"
        d="M27.745,14.245A3.755,3.755,0,1,0,31.5,18,3.754,3.754,0,0,0,27.745,14.245Z"
        fill="currentColor"
      />
      <path
        id="Path_10"
        data-name="Path 10"
        d="M18,14.245A3.755,3.755,0,1,0,21.755,18,3.754,3.754,0,0,0,18,14.245Z"
        fill="currentColor"
      />
      <path
        id="Path_11"
        data-name="Path 11"
        d="M8.255,14.245A3.755,3.755,0,1,0,12.009,18,3.754,3.754,0,0,0,8.255,14.245Z"
        fill="currentColor"
      />
      <path
        id="Path_12"
        data-name="Path 12"
        d="M27.745,4.5A3.755,3.755,0,1,0,31.5,8.255,3.754,3.754,0,0,0,27.745,4.5Z"
        fill="currentColor"
      />
      <path
        id="Path_13"
        data-name="Path 13"
        d="M18,4.5a3.755,3.755,0,1,0,3.755,3.755A3.754,3.754,0,0,0,18,4.5Z"
        fill="currentColor"
      />
      <path
        id="Path_14"
        data-name="Path 14"
        d="M8.255,4.5a3.755,3.755,0,1,0,3.755,3.755A3.754,3.754,0,0,0,8.255,4.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default PinSvg;
