import React from 'react';

const PlaySvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M4.25447 0.440985C2.45135 -0.593307 0.989502 0.253997 0.989502 2.33198V17.6666C0.989502 19.7466 2.45135 20.5928 4.25447 19.5595L17.6577 11.8729C19.4614 10.8382 19.4614 9.16191 17.6577 8.1275L4.25447 0.440985Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default PlaySvg;
