import React from 'react';

export const PadlockSvg: React.FC<React.SVGProps<SVGSVGElement>> = ({
  className,
}: {
  className?: string;
}) => (
  <svg
    width="15"
    height="20"
    viewBox="0 0 15 20"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.125 7.5H12.5V5C12.5 2.2425 10.2575 0 7.5 0C4.7425 0 2.5 2.2425 2.5 5C2.5 7.7575 2.5 7.5 2.5 7.5H1.875C0.841667 7.5 0 8.34083 0 9.375V18.125C0 19.1592 0.841667 20 1.875 20H13.125C14.1583 20 15 19.1592 15 18.125V9.375C15 8.34083 14.1583 7.5 13.125 7.5ZM4.16667 5C4.16667 3.16167 5.66167 1.66667 7.5 1.66667C9.33833 1.66667 10.8333 3.16167 10.8333 5V7.5H4.16667C4.16667 7.5 4.16667 6.83833 4.16667 5ZM8.33333 13.935V15.8333C8.33333 16.2933 7.96083 16.6667 7.5 16.6667C7.03917 16.6667 6.66667 16.2933 6.66667 15.8333V13.935C6.17083 13.6458 5.83333 13.1142 5.83333 12.5C5.83333 11.5808 6.58083 10.8333 7.5 10.8333C8.41917 10.8333 9.16667 11.5808 9.16667 12.5C9.16667 13.1142 8.82917 13.6458 8.33333 13.935Z"
      fill="currentColor"
    />
  </svg>
);

export default PadlockSvg;
