import { Modal } from 'antd';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  visible: boolean;
  children: ReactNode;
  forceRender?: boolean;
  onOk: () => void;
  onCancel: () => void;
};
const LargeModal: React.FunctionComponent<Props> = props => {
  const { visible, onCancel, onOk, children, forceRender } = props;
  return (
    <StyledModal
      title=""
      visible={visible}
      footer={null}
      centered
      destroyOnClose
      forceRender={forceRender}
      style={{
        margin: 0,
        maxWidth: '90%',
        height: '40%',
        width: '90%',
        top: 0,
        left: 0,
        padding: 0,
      }}
      onOk={onOk}
      onCancel={onCancel}
    >
      {children}
    </StyledModal>
  );
};

export default LargeModal;

const StyledModal = styled(Modal)`
  &&&&&& .ant-modal {
    margin: 0;
  }
  & .ant-modal-header,
  .ant-modal-footer {
    border: none;
  }

  .ant-modal-header {
    display: none;
  }

  .ant-modal-body {
    padding: 0;
    height: 100%;
    // & > div {
    //   height: 100%;
    // }
  }

  .ant-modal-content {
    height: 100%;
    width: 100%;
    margin: 0;
  }
  .ant-modal-close {
    display: none;
  }
`;
