import React from 'react';
import styled, { css } from 'styled-components';

import theme from '../../assets/theme';

const MaxWidthContainer = ({
  children,
  style,
  backgroundImage,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  backgroundImage?: string;
}) => {
  return (
    <Container $backgroundImage={backgroundImage} style={{ ...style }}>
      <Centered style={{ ...style }}>{children}</Centered>
    </Container>
  );
};

export default MaxWidthContainer;

const Container = styled.div<{ $backgroundImage?: string }>`
  width: 100%;
  ${({ $backgroundImage }) =>
    $backgroundImage &&
    css`
      background: url(${$backgroundImage}) ${theme.colors.orange} right bottom / 50% auto
        no-repeat;
      background-size: contain;

      @media (max-width: 1000px) {
        background: url(${$backgroundImage}) ${theme.colors.orange} right bottom / 50%
          auto no-repeat;
      }
    `}
`;

const Centered = styled.div`
  width: 100%;
  max-width: 1128px;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1128px) {
    max-width: 100%;
    padding: 0px 20px !important;
  }
`;
