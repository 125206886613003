import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import theme from '../../../assets/theme';
import { foldersCacheKey } from '../../../hooks/useFolders';
import { templatesCacheKey } from '../../../hooks/useTemplates';
import useTenants from '../../../hooks/useTenants';
import useWorkSpace from '../../../hooks/useWorkSpace';
import { workspaceFoldersCacheKey } from '../../../hooks/useWorkspaceFolders';
import { workspaceTemplatesCacheKey } from '../../../hooks/useWorkspaceTemplates';
import backendService from '../../../services/backendService';
import { Button } from '../../_atoms/Button';

const MyPresentationsButton = ({ primaryActionText }: { primaryActionText: string }) => {
  const router = useRouter();
  const { data } = useTenants();
  const { workspaceId } = useWorkSpace();

  const redirect = data ? `/${data?.user.activeTenantId}/lessons` : '/lessons';

  const queryClient = useQueryClient();
  const tenantId = data?.user.activeTenantId;

  useEffect(() => {
    // If we render the button to navigate to workspace - prefetch some data to give
    // the user a 10/10 experience

    if (workspaceId && tenantId) {
      queryClient.prefetchQuery(
        [workspaceFoldersCacheKey],
        async () => {
          const folders = await backendService.instance.workspace.listWorkspaceFolders(
            workspaceId,
            tenantId,
          );
          return folders;
        },
        { staleTime: 5000 },
      );
      queryClient.prefetchQuery(
        [workspaceTemplatesCacheKey, workspaceId, undefined],
        async () => {
          const templates = await backendService.instance.workspace.listWorkspaceTemplates(
            workspaceId,
            undefined,
            tenantId,
          );
          return templates;
        },
        { staleTime: 5000 },
      );
    }
  }, [workspaceId, data, tenantId, queryClient]);

  useEffect(() => {
    if (tenantId) {
      queryClient.prefetchQuery(
        foldersCacheKey,
        async () => {
          const folders = await backendService.instance.template.listFolders(tenantId);
          return folders;
        },
        {
          staleTime: 1000 * 60 * 5,
        },
      );
      queryClient.prefetchQuery(
        [templatesCacheKey],
        async () => {
          const templates = await backendService.instance.template.listTemplates(
            tenantId,
          );
          return templates;
        },
        {
          staleTime: 1000 * 60 * 5,
        },
      );
    }
  }, [queryClient, tenantId]);

  return (
    <Button
      type="primary"
      styles={{
        backgroundColor: theme.colors.orange,
        borderRadius: '20px',
      }}
      onClick={() => {
        router.push(redirect);
      }}
    >
      {primaryActionText}
    </Button>
  );
};

export default MyPresentationsButton;
