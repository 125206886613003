import React from 'react';

const LiveFrame = () => (
  <svg
    width="564"
    height="300"
    viewBox="0 0 564 300"
    fill="none"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M564 0H0V300H564V0ZM503.486 133.411C489.098 103.687 454.856 78.3019 412.268 60.4677C369.968 42.6336 319.323 32.1901 266.376 30.7441C213.429 29.1374 158.18 36.3675 123.937 55.1657C89.9821 73.8032 77.0331 103.848 64.9473 135.018C53.1494 166.187 42.2147 198.482 65.5229 218.405C88.169 237.605 143.14 245.431 194.807 252.786C196.318 253.001 197.825 253.216 199.329 253.43C202.956 253.959 206.561 254.488 210.145 255.014C258.872 262.167 303.801 268.762 350.976 265.32C401.621 261.624 454.856 246.361 483.919 221.779C512.982 197.196 518.162 163.135 503.486 133.411Z"
      fill="#139982"
    />
  </svg>
);

export default LiveFrame;
